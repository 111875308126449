<template>    
    <div class="hero__outer">
        <div class="hero hero__inner" id="hero">        
            <div class="hero-container">
                <!-- CONTENT -->
                <div class="container py-sm-5">
                    <div class="row position-relative">
                        <img src="@/assets/imgs/hero-tunderke2.png" class="img-fluid fairy"/>
                        <img src="@/assets/imgs/hero-mano.png" class="img-fluid cobold"/>

                        <div class="col-12 col-md-6 d-flex justify-content-center flex-column">
                            <h1 class="text-white text-uppercase text-lg-start text-center">
                                <img src="@/assets/imgs/hero-headline.png" class="img-fluid position-relative" style="z-index:1;"/>
                            </h1>
                            <div class="text-center d-md-block d-none">
                                <p class="text-white font-primary mb-2 fw-400">A promóció időtartama:</p>
                                <img src="@/assets/imgs/date.svg" class="img-fluid"/>
                                <!--<p class="hero__date-wrap fs-24 text-primary text-uppercase fw-bold mb-0" >2024. október 30 – december 24.  </p>-->
                                <div class="text-center text-dark mt-4">
                                    <button v-if="$store.state.promoStatus == 'live'"
                                        class="btn btn-white mb-3 order-md-2 me-md-2 md-btn"
                                        v-scroll-to="{el:'#palyazat',  offset: -80}"
                                        @click="setGtm('Játszom', 'jatszom')"
                                    >
                                        Játszom!
                                    </button>
                                    <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                                        Játékszabályzat
                                    </a>   
                                </div>                                      
                            </div>
                        </div> 
                        <div class="col-12 col-md-6">
                            <img src="@/assets/imgs/hero-product.png" class="img-fluid mt-lg-4 d-none d-md-block"/>
                        </div>   
                        <div class="col-12 d-flex align-items-end justify-content-center flex-row">
                            <img src="@/assets/imgs/hero-nyeremeny-1.png" class="img-fluid hero-winnings1 d-md-block d-none"/>
                            <img src="@/assets/imgs/hero-nyeremeny-2.png" class="img-fluid hero-winnings2 d-md-block d-none"/>
                            <img src="@/assets/imgs/hero-nyeremeny-3.png" class="img-fluid hero-winnings3 d-md-block d-none"/>
                            <img src="@/assets/imgs/hero-nyeremeny-mobile.png" class="img-fluid d-block d-md-none"/>
                        </div>                
                    </div>  
                </div>  
            </div>      
        </div>
        <div class="text-center d-block d-md-none py-5 px-5" style="background: #2DA9A9;">
            <p class="text-white font-primary mb-2 fw-400">A promóció időtartama:</p>
            <img src="@/assets/imgs/date.svg" class="img-fluid"/>
            <!--<p class="hero__date-wrap fs-24 text-primary text-uppercase fw-bold mb-0" >2024. október 30 – december 24.  </p>-->
            <div class="text-center text-dark mt-4 mx-5">
                <button v-if="$store.state.promoStatus == 'live'"
                    class="btn btn-white mb-3 order-md-2 me-md-2 md-btn"
                    v-scroll-to="{el:'#palyazat',  offset: -80}"
                    @click="setGtm('Játszom', 'jatszom')"
                >
                    Játszom!
                </button>
                <a class="btn btn-secondary me-0 ms-lg-2 order-md-1 mb-3 md-btn" :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                    Játékszabályzat
                </a>   
            </div>                                      
        </div>
        <div class="bg-blue text-center py-2 px-5">
            <p class="hero__info-text py-5 mb-0 text-white">A vásárlást igazoló eredeti bizonylatot a nyereményjáték lezárulta után további 1 hónapig <b>(2025. január 24-ig)</b>, illetve nyertesség esetén a nyeremények átadásáig meg kell őrizni. A képek csak illusztrációk.</p>
        </div>
    </div>            
</template>

<script>

//import Ribbon from '@/components/Ribbon.vue'

export default {
    components:{
        //Ribbon
    },
    methods: {      
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'gomb_kattintas'
            })
        }
    }
}
</script>