<template>
    <div id="jatekszabaly" class="rules position-relative block--big">
        <div class="container-xl">

            <div class="row px-2">
                <h2 class="text-black text-center mb-5">
                    A játék lépései
                </h2>
                <div class="col-12 d-flex flex-md-row flex-column position-relative my-5 flex-md-nowrap flex-wrap justify-content-center align-items-center rules-dices">
                    <img src="@/assets/imgs/kocka.png" class="kocka"/>
                    <div class="bg-primary d-flex justify-content-start align-items-center flex-column text-white rules-box">
                        <img src="@/assets/imgs/vasarolj.svg" class="img-fluid mb-3"/>
                        <h3 class="text-uppercase">Vásároljon</h3>
                        <p>bármilyen dobozos LEGO® építőjátékot a játék ideje alatt!</p>
                    </div>
                    <div class="bg-yellow d-flex justify-content-start align-items-center flex-column text-black rules-box">
                        <img src="@/assets/imgs/tolts.svg" class="img-fluid mb-3"/>
                        <h3 class="text-uppercase">Töltse fel</h3>
                        <p>a vásárlást igazoló bizonylat adatait az oldalon és induljon a napi, heti, havi vagy akár fődíjért!</p>
                    </div>
                    <div class="bg-primary d-flex justify-content-start align-items-center flex-column text-white rules-box">
                        <img src="@/assets/imgs/orizd.svg" class="img-fluid mb-3"/>
                        <h3 class="text-uppercase">Őrizze meg</h3>
                        <p>2025. január 24-ig a vásárlást igazoló eredeti bizonylatot sértetlen, ép állapotban!</p>
                    </div>
                    <div class="bg-primary d-flex justify-content-start align-items-center flex-column text-white rules-box">
                        <img src="@/assets/imgs/nyerd.svg" class="img-fluid mb-3"/>
                        <h3 class="text-uppercase">Nyerje meg</h3>
                        <p>az értékes nyereményeket!</p>
                    </div>
                </div>
                <!-- BUTTONS      -->
                <div class="col-12 flex-wrap flex-md-nowrap justify-content-center d-flex py-7">
                    <button
                        class="btn btn-primary mb-4 mb-md-0 me-0 me-md-4"
                        v-scroll-to="{el:'#palyazat',  offset: -80}"
                        @click="setGtm('Játszom', 'jatszom')"
                    >
                        Játszom
                    </button>
                    <a class="btn btn-black-border " :href="$store.state.file_url" target="_blank" @click="setGtm('Játékszabályzat', 'jatekszabalyzat')">
                        Játékszabályzat
                    </a> 
                </div>  
                
            </div> <!-- end of ROW -->
        </div>        
    </div>
</template>

<script>
export default {
    data () {
        return {
            activeTab: 1,
             tabs: [ 
                {
                    id: 1,
                    name: "Vásárolj!",
                    img: "imgs/vasarolj.svg",
                    imgActive: "imgs/vasarolj-active.svg",
                    content:[
                        ["step-1.svg",`Vásárolj egyszerre legalább<span class='fw-bold'> 1 db</span>, a promócióban részt vevő <a href="#termekek" style="font-weight:bold;" class='text-white'> Finish gépi mosogatószert vagy bármilyen Finish gépi mosogatási kiegészítő terméket</a> az <b>Auchan áruházakban,</b> vagy az <b>Auchan Online Áruházban</b> (a <a href="https://auchan.hu/shop" target="_blank" class="fw-bold" style='color:#FEEF0A;'>https://auchan.hu/shop</a> oldalon keresztül), hogy részt vehess a játékban.`, "1"],
                        ["step-2.svg",`A nyereményjáték időtartama: <span class='fw-bold d-block d-lg-inline-block'><b> 2024. október 03 - október 30.</b></span>`, "2"]
                    ]
                },
                {
                    id: 2,
                    name: "Regisztrálj!",
                    img: "imgs/regisztralj.svg",
                    imgActive: "imgs/regisztralj-active.svg",
                    content: [
                        [
                            "step-3.svg","Regisztrálj, majd töltsd fel a vásárlást igazoló bizonylat adatait és játssz a nyereményekért!", "3"
                        ],
                        [
                            "step-4.svg","A vásárlást igazoló eredeti bizonylat sértetlen, ép állapotban történő megőrzése <strong style='white-space:nowrap'>2024. november 30-ig szükséges</strong>.", "4"
                        ]
                                             
                    ]
                    
                },
                {
                    id: 3,
                    name: "Nyerj!",
                    img: "imgs/nyerj.svg",
                    imgActive: "imgs/nyerj-active.svg",
                    content: [
                        [
                            "step-5.svg","Ne feledd, minél többször vásárolsz a promócióban részt vevő termékekből egyidejűleg legalább 1 db-ot, annyiszor több esélyed van a nyerésre!", "5"
                        ],
                        [
                            "step-6.svg","A játék ideje alatt előre meghatározott <strong>nyerőidőpontokban</strong> (08:00 és 22:00 óra között) megnyerhető<strong> naponta 5 db 10.000 Ft értékű Auchan ajándékkártya</strong>", "6"
                        ]
                    ]
                    
                }               
            ]
        }
    },
    methods:{
        setGtm(btnName, eventName){
            this.GTtrackGA4({
                'event' : eventName,
                'category': 'jatekleiras',
                'action': 'gomb_kattintas',
                'button': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
            })
        },
        setActiveTab(index){
            this.activeTab = index;
        }
    }
}
</script>