<template>
    <div id="termekek" class="products block--big position-relative">
        <div class="container-xl">                    
            <div class="row">                
                <div class="col-md-12 d-flex flex-column text-center">
                    <h2 class="font-header text-center mb-7 text-black">
                        Részt vevő termékek
                    </h2>
                </div>
                <div class="col-12 text-center">
                    <img src="@/assets/imgs/products1.png" alt="finish" class="img-fluid mx-auto">
                </div>
                <div class="col-12 text-center my-5">                    
                    <a class="btn btn-primary" @click="setGtm('Termékek listája')" :href="$api_conf.apiDomain+'/pdf/resztvevo_termekek.pdf?'" target="_blank">
                        Részt vevő termékek listája
                    </a>
                </div>
                             
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <p class="text-center text-black fs-14" >A képek csak illusztrációk.</p>
                    </div>
                </div>  
            </div>         
        </div>
    </div>
</template>

<script>

export default {
    data() {
      return {
        settings: {
            arrows: true,
            dots: false,
            slidesToShow: 6,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [{
                breakpoint: 1200,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 992,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 530,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }]
        },
      }
    },
    methods: {   
        setGtm(btnName){
            this.GTtrackGA4({
                'event' : 'termekek',
                'category': 'jatekban_reszt_vevo_termekek',
                'action': 'gomb_kattintas',
                'label': btnName,                
                'clicked_text':btnName,
                'button': 'termekek'
            })
        }
    }
}
</script>