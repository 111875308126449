<template>
    <div id="nyertesek" class="winners block--big bg-primary">
        
            <h2 class="text-center mt-0 text-white mb-5">
                Nyertesek
            </h2>
            <div class="container-xl">
                <div class="row">                
                    <div class="col-12 col-xl-10 offset-xl-1 mb-0 mb-md-5 text-center order-1">
                        <p class="m-0 winners__text text-black">                        
                            A <b>napi nyeremények</b> nyertesei az adott napot követő első munkanapon (pénteki/szombati/vasárnapi nyeremények esetében a következő hétfőn, ünnepnap esetében a következő munkanapon) kerülnek kiértesítésre.<br><br>A <b>heti nyeremények</b> nyertesei minden játékhetet követően szerdánként kerülnek kiértesítésre, kivéve a játék utolsó hetét, ahol az értesítés ünnepek után, <b>2025 január 02-án</b> történik meg.<br><br>A <b>havi nyeremények</b> sorsolására a játékhónapok végét követően kerül sor, az alábbi időpontokban közjegyző jelenlétében, gépi sorsolással, nyilvánosan.<br><br>1. Játékhavi sorsolás: <b>2024. 11.28. 14:00 óra</b> <br>2. Játékhavi sorsolás: <b>2025. 01.06. 14:00 óra</b>, a fődíj sorsolását követően. <br><br>A <b>Fődíj</b> sorsolására <b>2025. Január 06-án 14:00</b> órakor, közjegyző jelenlétében, gépi sorsolással, nyilvánosan kerül sor. 
                        </p>
                    </div>                                                
                </div>
            </div>
            <div class="winners__winning-wrap">
                
                <div class="container-xl py-0">
                    <div class="row position-relative">
                        <div class="col-12">
                            <img src="@/assets/imgs/hero-tunderke.png" class="img-fluid fairy"/>
                            <img src="@/assets/imgs/yeti.png" class="img-fluid yeti"/>
                            <img src="@/assets/imgs/hero-winnings.png" class="img-fluid d-none d-sm-block" style="z-index:1;position:relative;">
                            <img src="@/assets/imgs/hero-nyeremeny-mobile.png" class="img-fluid d-block d-sm-none"/>
                        </div>
                    </div>
                </div>
            </div>
            <!-- NYertesek -->
            <h3 class="text-black fw-bold text-center mt-5 pt-5">A nyertesek megtekintéséhez kattintson az adott napra!</h3>
            <div class="container-xl block--big py-5 ">
                <div class="row bg-white py-5" style="border-radius:20px;">

                    <div class="col-12">
                        <!-- TAB -->
                        <div class="winners-button-select mb-5" >
                            <button 
                                class="btn fw-700 px-3" 
                                :class="winnerState === 'daily' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('daily')"
                            >
                                Napi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                            <button 
                                class="btn fw-700 px-3" 
                                :class="winnerState === 'weekly' ? 'active text-secondary':''"  
                                v-on:click="setWinnerState('weekly')"
                            >
                                Heti <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button> 
                         <button 
                                class="btn fw-700 px-3"
                                :class="winnerState === 'monthly' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('monthly')"
                            >
                                Havi <span class="d-none d-md-block ms-1"> nyertesek</span>
                            </button>
                            <button 
                                class="btn fw-700 px-3"
                                :class="winnerState === 'main' ? 'active text-secondary':''" 
                                v-on:click="setWinnerState('main')"
                            >
                                Fődíj <span class="d-none d-md-block ms-1"> nyertese</span>
                            </button>
                        </div>
                    </div>
                    <div class="col-12 col-lg-4 text-lg-end text-center mb-3" v-if="winnerState === 'daily'">
                        <Datepicker :inline="true" :disabled-date="disabledDates" v-model="dateValue" value-type="format" format="YYYY-MM-DD"></Datepicker>
                    </div>
                    <div class="col-12" :class="winnerState !== 'daily'? '':'col-lg-8'">
                        <!-- TABLE -->
                        <div class="winners__border mb-5">
                            <div class="winners__table-wrap winners__table-wrap--heading">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  >
                                    <thead>
                                        <tr class="winners-top font-primary" style="border-bottom: 1px solid #ffffff;">
                                            <th class="w-33 text-yellow">Nyertes neve</th>
                                            <th class="w-33 text-yellow">Város</th>       
                                            <th class="w-33 text-yellow">Nyeremény</th>                      
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                            <div class="winners__table-wrap winners__table-wrap--bg text-dark">                        
                                <table cellspacing="0" cellpadding="0" border="0"  class="winners__block w-100 custom-table"  :class="[`winner-table_${winnerState}`]" >
                                    <tbody v-if="filteredWinners && filteredWinners.length">                          
                                        <tr class="winners-list fs-14 text-white" v-for="(winner, winnerindex) in filteredWinners" :key="winnerindex">
                                            <td class="winners-id fw-700 text-white w-33 p-3">
                                                <div>{{ winner.name }}</div>                                     
                                            </td>                          
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span>{{winner.field_1}}</span>
                                            </td>
                                            <td class="winners-product text-white text-right text-md-left w-33 p-3">
                                                <span v-if="winner.type == 'daily'">1 db LEGO® Creator szett</span>
                                                <span v-if="winner.type == 'weekly_hatizsak'">1 db LEGO® hátizsák</span>
                                                <span v-if="winner.type == 'weekly_borond'">1 db LEGO® bőrönd</span>
                                                <span v-if="winner.type == 'monthly'">1 db LEGO® Eiffel- torony</span>
                                                <span v-if="winner.type == 'main'"> 4 fős dubai utazás</span>
                                            </td>
                                        </tr>                            
                                    </tbody>
                                    <tbody v-else>
                                        <!-- v-if="loginShow" -->
                                        <tr>
                                            <td class="no-winner text-white text-center py-3" colspan="2">
                                                Hamarosan kiderül, ki lett a nyertes
                                            </td>                                
                                        </tr>
                                        <!--<tr v-else>
                                            <td class="no-winner text-center py-3" colspan="2">
                                                A kisorsolt nyertesek részére a nyeremények átadása megtörtént!
                                            </td>
                                        </tr>-->
                                    </tbody>
                                </table>                        
                            </div>

                            
                        </div>

                        <div class="d-flex justify-content-center py-4" v-if="needMoreBtn">
                                <button target="_blank" type="button" class="btn btn-primary" v-on:click="moreWinners(true)">Mutasd a további nyerteseket</button>
                        </div>
                    </div>           
                    
                </div>  
            </div> 
             <img src="@/assets/imgs/winners-bottom.png" class="img-fluid mx-auto d-block">
            <!-- <div class="col-12">
                <p class="text-center text-white fs-14">A nyeremények képe csak illusztráció!</p>
            </div>-->
        
    </div>
</template>


<script>
import Datepicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import axios from 'axios'

export default {
    components: {
        Datepicker
    },
    data () {
        return {
            winnersAll:[],
            winnerState:'daily',
            needMoreBtn: false,
            needMoreWinners: false,
            dateValue: "",
            refreshKey: 0
        }
    },
    computed:{
        filteredWinners() {
            let result = []
            let _this = this
            this.refreshKey;

            if(this.winnersAll ?? []){                
                
                //result = this.winnersAll.filter(w => w.type === this.winnerState)

                _this.winnersAll.forEach(w => {
                    if (_this.winnerState === 'daily'){
                        if(_this.dateValue === w.winning_time){
                            if(w.type === "daily"){
                                result.push(w)
                            }
                        }
                    }
                    if (_this.winnerState === 'weekly'){
                        if(w.type === "weekly_hatizsak" || w.type === "weekly_borond"){
                            result.push(w)
                        }
                    }

                    if (_this.winnerState === 'monthly'){
                        if(w.type === "monthly"){
                            result.push(w)
                        }
                    }

                    if (_this.winnerState === 'main'){
                        if(w.type === "main"){
                            result.push(w)
                        }
                    }

                })

                if(result.length > 10 && !this.needMoreWinners){
                    this.checkBtn(true);
                    return result.slice(0,10);
                }else{
                    this.checkBtn(false);
                    return result;
                }
            }
            this.checkBtn(false);
            return result;
        }
    },
    watch:{      
        dateValue(){
            this.refreshKey++;
            console.log(this.dateValue);

        }
    },
    methods: {
        disabledDates: function (date) {
            return date < new Date(2024, 9, 30) || date > new Date(2024, 11, 24);
        },
        setDailyWinners(date){  
            console.log(date);

        },
        setWinnerState(newState) {
            this.moreWinners(false); 
            this.winnerState = newState;      
        },
        checkBtn(newState) {
            this.needMoreBtn = newState;
        },
        moreWinners(newState){
            this.needMoreWinners = newState;
        },
      /*  selectWinners(){
            let _this = this
            this.wins.forEach( item => {                
                let winTypeWinners = _this.winnersAll.filter( element => element.type == item.type )
                //console.log('winTypeWinners',winTypeWinners)
                if (winTypeWinners)
                    item.winners = winTypeWinners
            })
        }*/
    },
    created(){

        this.dateValue = new Date();

        
        axios.get(process.env.VUE_APP_API_URL + 'step1/winners')
        .then((resp) => {
          this.winnersAll = resp.data.winners
         // this.winnerState = "napi";
          //this.selectWinners()
        }).catch((err) => {
            console.log(err)
        })

      //  this.setWinnerState('napi')
    }
    
}
</script>